<template>
  <v-main class="container-prosker">
    <v-col cols="12" class="m-0 p-0">
      <hero-banner
        :title="$t('proskerBannerTitle')"
        :img-url="'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg'"
        :on-cta-click="goToSubscriptions"
        :text-link-route="'social.subscription-page'"
        :cta-text="$t('begin')"
        :text-link-text="$t('wantToHire')"
      />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <p class="secondary-prosk mx-auto w-75 text-h5 text-md-h3 text-center">{{ $t('servicePlatform') }}</p>
    </v-col>
    <v-col cols="10" offset="1">
      <save-button :save="goToSubscriptions" :size="'block'" :loading="false" :message="$t('startNow')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <card-columns :cards="cards" :cols="3" />
    </v-col>
    <v-col cols="12" class="m-0 pb-8 text-center">
      <save-button :save="goToSubscriptions" :size="'x-large'" :css-classes="'bg-prosk-secondary primary-prosk'" :loading="false" :message="$t('wantToSignUp')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8 text-center grey lighten-2">
      <video-banner :video-url="video.url" :title="video.title" />
      <save-button :save="goToSubscriptions" :size="'large'" :css-classes="'bg-prosk-secondary primary-prosk mt-8'" :loading="false" :message="$t('wantToSignUp')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8">
       <number-tiles :cols="2" :tiles="tiles" />
    </v-col>
    <v-col cols="12" class="m-0 py-8 text-center">
       <p class="primary-prosk mx-auto w-50 text-h5 text-md-h3 text-center">{{ $t('proskerVisibilityTitle') }}</p>
       <p class="primary-prosk mx-auto w-50 text-h5 text-md-h3 text-center mb-8">{{ $t('proskerVisibilityTitleTwo') }}</p>
       <p class="secondary-prosk mx-auto w-50 text-h5 text-md-h5 text-center mb-0">{{ $t('proskerBusinessTitle') }}</p>
       <p class="secondary-prosk mx-auto w-50 text-h6 text-center">{{ $t('proskerBusinessSubtitle') }}</p>
       <save-button :save="goToSubscriptions" :size="'large'" :css-classes="'bg-prosk-secondary primary-prosk mt-8'" :loading="false" :message="$t('wantToSignUp')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8 grey lighten-2">
      <Testimonials />
    </v-col>
  </v-main>
</template>
<script>
import Testimonials from '@/components/socialvue/testimonials/Testimonials';
import HeroBanner from '@/components/socialvue/banners/HeroBanner';
import CardColumns from '@/components/socialvue/cards/CardColumns';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import NumberTiles from '@/components/socialvue/tiles/NumberTiles';
import SaveButton from '@/components/socialvue/buttons/SaveButton';
import { USER_TYPE_CLIENT } from '@/misc/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'HomeProskers',
  components: {
    Testimonials,
    HeroBanner,
    SaveButton,
    CardColumns,
    VideoBanner,
    NumberTiles
  },
  data () {
    return {
      cards: [
        {
          icon: 'mdi-check-circle-outline',
          title: this.$t('proskerCardOneTitle'),
          text: this.$t('proskerCardOneText'),
          type: 'icon-card',
          iconColor: '#59D79D'
        },
        {
          icon: 'mdi-check-circle-outline',
          title: this.$t('proskerCardTwoTitle'),
          text: this.$t('proskerCardTwoText'),
          type: 'icon-card',
          iconColor: '#59D79D'
        },
        {
          icon: 'mdi-check-circle-outline',
          title: this.$t('proskerCardThreeTitle'),
          text: this.$t('proskerCardThreeText'),
          type: 'icon-card',
          iconColor: '#59D79D'
        }
      ],
      video: {
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        title: this.$t('videoTitle'),
        ctaText: this.$t('wantToSignUp')
      },
      tiles: [
        {
          title: this.$t('proskerTilesOneTitle'),
          text: this.$t('proskerTilesOneText')
        },
        {
          title: this.$t('proskerTilesTwoTitle'),
          text: this.$t('proskerTilesTwoText')
        },
        {
          title: this.$t('proskerTilesThreeTitle'),
          text: this.$t('proskerTilesThreeText')
        },
        {
          title: this.$t('proskerTilesFourTitle'),
          text: this.$t('proskerTilesFourText')
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  created () {
    this.setImportedConstants();
    if (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) {
      this.$router.push({ name: 'social.clients-page' });
    }
  },
  methods: {
    setImportedConstants () {
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
    },
    goToSubscriptions () {
      this.$router.push({ name: 'social.subscription-page' });
    }
  }
};
</script>
