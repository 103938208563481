<template>
  <v-container>
    <v-row class="bg-prosk-secondary">
      <v-col cols="12" :md="12 / cols" v-for="(item, i) in tiles" :key="i">
        <v-card flat class="pa-8 bg-transparent">
          <div class="d-flex">
            <p class="primary-prosk text-h3 mr-8">{{ i + 1 }}</p>
            <div class="white--text">
              <p class="text-h5">{{ item.title }}</p>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'NumberTiles',
  props: ['tiles', 'cols']
};
</script>
