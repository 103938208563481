<template>
  <v-card>
    <v-img
      src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
      height="200px"
    ></v-img>

    <v-card-title>
      {{ card.title }}
    </v-card-title>

    <v-card-subtitle>
      {{ card.text }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'BannerCard',
  props: ['card']
};
</script>
