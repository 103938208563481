<template>
  <v-container>
      <v-row dense>
        <v-col v-for="(item, i) in cards" :md="12/cols" cols="12" :key="i">
          <icon-card :card="item" v-if="item.type === 'icon-card'" />
          <banner-card :card="item" v-if="item.type === 'banner-card'" />
        </v-col>
      </v-row>
    </v-container>
  </template>
<script>
import IconCard from '@/components/socialvue/cards/IconCard';
import BannerCard from '@/components/socialvue/cards/BannerCard';
export default {
  name: 'CardColumns',
  components: { IconCard, BannerCard },
  props: ['cards', 'cols']
};
</script>
