<template>
  <v-card
    :color="card.color"
    flat
    min-height="150"
    class="bg-transparent"
  >
    <div class="d-flex justify-space-between align-items-center">
      <v-avatar
        class="ma-3 col-2"
        tile
      >
        <v-icon
          x-large
          :color="card.iconColor"
        >
          {{ card.icon }}
        </v-icon>
      </v-avatar>

      <div class="col-10">
        <v-card-title
          class="text-h5 secondary-prosk"
          v-text="card.title"
        ></v-card-title>

        <v-card-subtitle class="secondary-prosk" v-text="card.text"></v-card-subtitle>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'IconCard',
  props: ['card']
};
</script>
